/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";

import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Col,
    Media,
    Badge,
    Spinner, Button, CardFooter, PaginationItem, Pagination, PaginationLink
} from "reactstrap";


import Header from "components/Headers/Header.js";
import {CodeBlock, solarizedDark} from "react-code-blocks";
import apiClient from "../http_utils";
import {Link} from "react-router-dom";
import {DropdownChecklist} from "../components/Atomic/DropdownChecklist";

const Dashboard = ({ userInfo }) => {

    const [selectedSourceOptions, setSelectedSourceOptions] = useState([]);
    const [sourceOptions, setSourceOptions] = useState([]);

    const [selectedInstanceTypeOptions, setSelectedInstanceTypeOptions] = useState([]);
    const [instanceTypeOptions, setInstanceTypeOptions] = useState([]);

    const [selectedRepositoryOptions, setSelectedRepositoryOptions] = useState([]);
    const [repositoryOptions, setRepositoryOptions] = useState([]);

    const [selectedStateOptions, setSelectedStateOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);

    const [otherRunners, setOtherRunners] = useState([]);
    const [runnersMeta, setRunnersMeta] = useState({});
    const [runnerDeletionInProgress, setRunnerDeletionInProgress] = useState(false);
    const [runnerDeletionUuid, setRunnerDeletionUuid] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)

    const [firstPageIndex, setFirstPageIndex] = useState(1)
    const [secondPageIndex, setSecondPageIndex] = useState(2)
    const [thirdPageIndex, setThirdPageIndex] = useState(3)

    const [timeElapsed, setTimeElapsed] = useState(0)
    const [filteredRunnersCount, setFilteredRunnersCount] = useState(0)

    useEffect(() => {
        getRunners()
    },[
        currentPage,
        selectedSourceOptions,
        selectedInstanceTypeOptions,
        selectedRepositoryOptions,
        selectedStateOptions,
    ]);

    function getRunners() {
        apiClient.get(`/api/runner?page=${currentPage}`, {
            params: {
                source: selectedSourceOptions,
                instance_types: selectedInstanceTypeOptions,
                repositories: selectedRepositoryOptions,
                state_types: selectedStateOptions,
            },
        }).then(response => {
            if (response.status === 200) {
                const responseData = response.data
                setTimeElapsed(responseData['time_elapsed'])
                setFilteredRunnersCount(responseData['filtered_runners_count'])
                setOtherRunners(responseData['runners'])
                setRunnersMeta(responseData['meta'])
                const filters = responseData['meta']['filters']

                let source_options = filters['source'].map(item => {
                    return {id: item, label: item}
                })
                setSourceOptions(source_options)

                let repository_options = filters['repositories'].map(item => {
                    return {id: item, label: item}
                })
                setRepositoryOptions(repository_options)

                let instance_type_options = filters['instance_types'].map(item => {
                    return {id: item, label: item}
                })
                setInstanceTypeOptions(instance_type_options)

                let state_options = filters['state_types'].map(item => {
                    return {id: item, label: item}
                })
                setStateOptions(state_options)

                if (responseData['runners'].length === 0) {
                    currentPage > 1 ? setCurrentPage(currentPage - 1): null
                    firstPageIndex > 2 ? setFirstPageIndex(firstPageIndex - 1): null
                    secondPageIndex > 3 ? setSecondPageIndex(secondPageIndex - 1): null
                    thirdPageIndex > 4 ? setThirdPageIndex(thirdPageIndex - 1): null
                }

            } else {
                console.log("Something wrong happened!")
            }
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    async function handleDeleteRunner(event){
        setRunnerDeletionInProgress(true);
        setRunnerDeletionUuid(event.target.id)
        event.preventDefault()
        const data = {
            "runner_uuid": event.target.id,
        }

        await apiClient.delete('/api/runner', {
            data: JSON.stringify(data)
        });
        setRunnerDeletionInProgress(false);
        setRunnerDeletionUuid(null)
        getRunners()
    }

    function renderTerminateButton(runner, buttonColor) {
        let button_val = <span
            className="btn-inner--icon icon-sm" id={runner.uuid} color="danger">
            <i className="far fa-stop-circle" id={runner.uuid}></i>
        </span>
        if (runner.state === "configured") {
            button_val = "Disable"
        }
        if (runnerDeletionInProgress && runnerDeletionUuid === runner.uuid) {
            button_val = <Spinner size="sm" animation="border" role="status"> </Spinner>
        }
        return (
            <Button
                className={"btn-sm"}
                id={runner.uuid}
                outline
                color={buttonColor}
                onClick={handleDeleteRunner}
                disabled={runner.state !== "created" && runner.state !== "configured"}
            >
                {button_val}
            </Button>
        )
    }

    function getCloudIcon(cloud) {
        if (cloud === "digitalocean") {
            return "fab fa-digital-ocean"
        } else if(cloud === "aws") {
            return "fab fa-aws"
        } else if(cloud === "gcp") {
            return "fab fa-google"
        } else if(cloud === "azure"){
            return "fab fa-windows"
        } else {
            return "fa fa-rocket mr-3"
        }

    }

    function getRunnerStateColor(runnerState) {
        if (runnerState === "terminated") {
            return "default"
        } else if (runnerState === "failed") {
            return "danger"
        } else if (runnerState === "requested") {
            return "warning"
        } else {
            return "success"
        }
    }

    function getStatusColor(runnerState) {
        if (runnerState === "terminated") {
            return "grey"
        } else if (runnerState === "failed") {
            return "#f85149"
        } else if (runnerState === "requested") {
            return "#9e6a02"
        } else {
            return "#3fb950"
        }
    }

    function renderTable(runners, buttonColor) {
        // const runners = state.otherRunners
        return runners.map((runner, index) => (
            <tr key={runner.uuid}>
                <th scope="row">
                    <h1>
                    <Media className="align-items-center">
                        <i className={getCloudIcon(runner.cloud)}/>
                    </Media>
                    </h1>
                </th>
                <td>
                    <Row>
                        <h3><i className="fas fa-circle mr-2" style={{color: getStatusColor(runner.state)}}></i> {runner.name}</h3>
                        <span className="ml-3">
                            {renderTerminateButton(runner, buttonColor)}
                        </span>
                    </Row>

                    <Row>
                        <h5 className="text-muted text-wrap">
                            • Image: {runner.image}
                        </h5>
                    </Row>
                    <Row>
                        <h5 className="text-muted">
                            • Instance Type: {runner.instance_type} {runner.gpu ? `(${runner.gpu.toUpperCase()})` : ""}
                        </h5>
                    </Row>
                    <Row>
                        <h5 className="text-muted">
                            • Source: {getRunnerSource(runner)}
                        </h5>
                    </Row>
                    <Row>
                        <h5 className="text-muted text-wrap">
                            • Labels: {runner.labels}
                        </h5>
                    </Row>
                    {getEventTime("Created At        ", runner.created_at)}
                    {getEventTime("Job Start            ", runner.job_start)}
                    {getEventTime("Job End              ", runner.job_end)}
                    {getEventTime("Terminated At ", runner.terminated_at)}
                    <Row>
                        <h5 className="text-muted">
                            <Link
                                className="mr-3"
                                to={{ pathname: "https://github.com/" + runner.repository + "/settings/actions/runners" }}
                                target="_blank"
                            >
                                <i className="fas fa-external-link-alt mr-1"></i>
                                {runner.repository}
                            </Link>
                        </h5>
                    </Row>
                    <Row>
                        {getCommitLink(runner)}
                    </Row>
                    <Row>
                        {getJobLink("Created By Job ", runner.created_by_job, runner.created_by_job_id, runner.created_by_run_id)}
                    </Row>
                    <Row>
                        {getJobLink("Picked By Job ", runner.picked_by_job, runner.picked_by_job_id, runner.picked_by_run_id)}
                    </Row>
                    <Row>
                        <h2><Badge color={getRunnerStateColor(runner.state)} pill>
                            {runner.state}
                        </Badge>
                        </h2>
                    </Row>
                </td>
                <td>
                    <Row>
                        {renderCodeBlock(runner.creation_response)}
                    </Row>
                </td>
            </tr>
        ))
    }

    function getRunnerSource(runner) {
        if (runner.ephemeral) {
            return <span>On-Demand<i className="fas fa-bolt ml-2"></i> </span>
        } else {
            return <span>Schedule<i className="fas fa-clock ml-2"></i></span>
        }
    }

    function getCommitLink(runner) {
        if (runner.commit_link) {
            return <h5 className="text-muted mr-3" style={{display: "flex", alignItems: "center"}}>
                <Link
                    className=""
                    to={{pathname: runner.commit_link}}
                    target="_blank"
                >
                    <i className="fas fa-external-link-alt mr-1"></i>
                    Commit
                </Link>
                <div className={"text-black-50 ml-2"}>{runner.commit_sha ? `${runner.commit_sha}` : ""}</div>
            </h5>
        }
    }

    function getJobLink(linkText, jobLink, jobId, runId) {
        if (jobLink) {
          return <h5 className="text-muted mr-3" style={{ display: "flex", alignItems: "center" }}>
                  <Link
                      className=""
                      to={{pathname: jobLink}}
                      target="_blank">
                      <i className="fas fa-external-link-alt mr-1"></i>
                      {linkText}
                  </Link>
              <div className={"text-black-50 ml-2"}>({jobId ? `Job: ${jobId}` : ""} {runId ? `Run: ${runId}` : ""})</div>
          </h5>
      } else {
          return ""
      }
    }

    function getEventTime(text, eventTime) {
        if (eventTime) {
            return <Row>
                <h5 className={""} style={{whiteSpace: "pre-wrap"}}>{text} {eventTime} UTC</h5>
            </Row>
        } else {
            return ""
        }
    }

    function getTable(runners, buttonColor) {
        return (
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                    <th scope="col">Cloud</th>
                    <th scope="col">Name/Image/Instance/State</th>
                    <th scope="col">Log</th>
                    <th scope="col"/>
                </tr>
                </thead>
                <tbody>
                {renderTable(runners, buttonColor)}
                </tbody>
            </Table>
        )
    }

    function getPaginationCardFooter() {
        return (
            <CardFooter className="py-4">
                <nav aria-label="...">
                    <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                    >
                        <PaginationItem className={firstPageIndex === 1 ? "disabled" : ""}>
                            <PaginationLink
                                href={"#" + currentPage}
                                onClick={(e) => updatePageItems("down")}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className={currentPage === firstPageIndex ? "active": ""}>
                            <PaginationLink
                                href={"#" + currentPage}
                                onClick={(e) => setCurrentPage(firstPageIndex)}
                            >
                                {firstPageIndex}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className={currentPage === secondPageIndex ? "active": ""}>
                            <PaginationLink
                                href={"#" + currentPage}
                                onClick={(e) => setCurrentPage(secondPageIndex)}
                            >
                                {secondPageIndex} <span className="sr-only">(current)</span>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className={currentPage === thirdPageIndex ? "active": ""}>
                            <PaginationLink
                                href={"#" + currentPage}
                                onClick={(e) => setCurrentPage(thirdPageIndex)}
                            >
                                {thirdPageIndex}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                href={"#" + currentPage}
                                onClick={(e) => updatePageItems("up")}
                            >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </nav>
            </CardFooter>
        )
    }

    function updatePageItems(direction) {
        if (direction === "up") {
            if (otherRunners.length > 0) {
                setCurrentPage(currentPage +  1)
                setFirstPageIndex(firstPageIndex + 1)
                setSecondPageIndex(secondPageIndex + 1)
                setThirdPageIndex(thirdPageIndex + 1)
            }
        } else {
            if (firstPageIndex > 1) {
                setCurrentPage(currentPage - 1)
                setFirstPageIndex(firstPageIndex - 1)
                setSecondPageIndex(secondPageIndex - 1)
                setThirdPageIndex(thirdPageIndex - 1)
            }
        }
    }

    function renderFilters() {
        return (
            <div className="mb-1">
                <Row className="ml-2">
                    <Col className="mb-0 col-sm-2">
                        <i className="fas fa-filter mr-2"></i>Filters
                    </Col>
                    <Col>
                        <DropdownChecklist
                            options={sourceOptions}
                            selectedOptions={selectedSourceOptions}
                            onChange={setSelectedSourceOptions}
                            placeholder="Source"
                            toggleColor="default"
                        />
                    </Col>
                    <Col>
                        <DropdownChecklist
                            options={instanceTypeOptions}
                            selectedOptions={selectedInstanceTypeOptions}
                            onChange={setSelectedInstanceTypeOptions}
                            placeholder="Instance Type"
                            toggleColor="default"
                        />
                    </Col>
                    <Col>
                        <DropdownChecklist
                            options={repositoryOptions}
                            selectedOptions={selectedRepositoryOptions}
                            onChange={setSelectedRepositoryOptions}
                            placeholder="Repository"
                            toggleColor="default"
                        />
                    </Col>
                    <Col>
                        <DropdownChecklist
                            options={stateOptions}
                            selectedOptions={selectedStateOptions}
                            onChange={setSelectedStateOptions}
                            placeholder="State"
                            toggleColor="default"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className={"d-flex justify-content-end mr-4"}>{renderCount()}</Col>
                </Row>
            </div>
        )
    }

    function renderCount() {
        return (
            <Row>
                <div className={"text-muted text-xs"}>returned {otherRunners.length} out of {filteredRunnersCount} runner(s) in {timeElapsed} seconds</div>
            </Row>
        )
    }

    function renderRunnersTable(runners, heading, buttonColor) {
      return (
          <div className="col">
              <Card className="shadow">
                  <CardHeader className="border-0">
                      <h3 className="mb-0">{heading}</h3>
                  </CardHeader>
                  {renderFilters()}
                  {getTable(runners, buttonColor)}
                  {getPaginationCardFooter()}
              </Card>
          </div>
      )
    }


    function renderDashboard() {
        return (
            <>
                <Header runner={runnersMeta}/>
                <Container className="mt--7" fluid>
                    <Row className="mt-4">
                        {renderRunnersTable(
                            otherRunners,
                            "Recent Runners",
                            "danger"
                        )}
                    </Row>
                </Container>
            </>
        );
    }

    function renderCodeBlock(log) {
        let numLines = 1
        if (typeof log === "string") {
            numLines = log.split(/\r\n|\r|\n/).length
        }
        const height = Math.min(numLines*30, 200)
        return (
            <div
                style={{
                    fontFamily: 'Fira Code',
                }}
            >
                <CodeBlock
                    language={"json"}
                    text={log}
                    customStyle={{
                        width: '500px',
                        height: `${height}px`,
                        overflow: 'scroll',
                    }}
                    theme={solarizedDark}
                    showLineNumbers={true}
                />
            </div>
        );
    }

        return (
            <>

                {/* Page content */}
                {Object.keys(userInfo).length !== 0 ? renderDashboard() : <></>}
                {/*{renderContainer()}*/}
            </>
        );
}

export default Dashboard;
