import React, { useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
    Label,
    Input
} from 'reactstrap';

export function DropdownChecklist({
                                      options,
                                      selectedOptions,
                                      onChange,
                                      placeholder = 'Select items...',
                                      toggleColor = 'default'
                                  }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleOptionChange = (optionId) => {
        onChange(
            selectedOptions.includes(optionId)
                ? selectedOptions.filter(id => id !== optionId)
                : [...selectedOptions, optionId]
        );
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="mb-4">
            <DropdownToggle caret color={toggleColor}>
                {selectedOptions.length > 0
                    ? `${placeholder} ${selectedOptions.length} selected`
                    : placeholder}
            </DropdownToggle>
            <DropdownMenu>
                {options.map(option => (
                    <DropdownItem key={option.id} toggle={false}>
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                id={`option-${option.id}`}
                                checked={selectedOptions.includes(option.id)}
                                onChange={() => handleOptionChange(option.id)}
                            />
                            <Label check for={`option-${option.id}`}>
                                <div className="text-black-50">{option.label}</div>
                            </Label>
                        </FormGroup>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}
